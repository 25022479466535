export enum SuccessMsgs {
  ChatInitiated = 'Chat has been initiated.',
  TokenLoaded = 'Token has been loaded.',
}

export enum ErrorMsgs {
  ConvAlreadyExists = 'Conversation with this phone number already exists',
  NumberUsedToAnotherChat = 'This phone number has been already used for another chat.',
  ChatNotBeInitiated = 'Chat has not been initiated. Please try again later.',
  ChatInitiatedByOther = 'Chat has been initiated by other concierge.',
  TokenNotLoadedShort = 'Token has not been loaded.',
  TokenNotLoaded = 'Token has not been loaded. Please try again later.',
}
