export const toPhoneNumberWithoutCharacters = (phone: string) => {
  const phoneArr = phone.split('');
  const notNeededCharacters = ['(', ')', ' ', '-'];
  const phoneArrFiltered = phoneArr.filter(
    (item) => !notNeededCharacters.includes(item)
  );
  const phoneStr = phoneArrFiltered.join('');

  return phoneStr;
};
