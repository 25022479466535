export const clinicianDefault = {
  uuid: '',
  full_name: '',
  phone: '',
  address: '',
  available_time_slot: '',
};

export const BULLET_COLORS = Object.freeze([
  '#29b0cf',
  '#fe6f93',
  '#feb92b',
  '#49f7d2',
  '#ee2e31',
  '#6a4199',
  '#f7a8b8',
  '#264653',
  '#e76f51',
  '#f3ffbd',
]);
