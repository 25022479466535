import { HCPStatus, HCPViewActionTaken } from '.';
import { ErrorType, PagingResult, USTimezonesValues } from '../CommonModels';
import {
  BaseLeadModel,
  LeadErrorModel,
  LeadModel,
  LeadRequestModel,
  LeadUpdateModel,
  ZipCode,
} from '../Lead';

export interface HealthCareProvider {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  city: string;
  speciality: string;
  office_address: string;
  license_number: string;
  npi_number: string;
  insurance: string;
  full_name: string;
  zip_code: ZipCode;
  timezone: USTimezonesValues;
  age: number;
  apartment: string;
}

export class HCPsResult extends PagingResult<BaseHCPModel> {}
export class HCPFlowsResult extends PagingResult<HCPFlowModel> {}

export class HCPFlowModel {
  id: string;
  name: string;
  redirect_url: string;
}
export class BaseHCPModel extends BaseLeadModel {
  health_care_provider: HealthCareProvider;
  pdrp_opt_in: boolean;
  hcp_status: HCPStatus;
}

export interface HCPModel extends LeadModel, BaseHCPModel {
  source_of_awareness: SourceOfAwareness;
}

export interface HCPUpdateModel extends LeadUpdateModel {
  health_care_provider: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    city: string;
    speciality: string;
    office_address: string;
    license_number: string;
    npi_number: string;
    insurance: string;
    full_name: string;
    zip_code: string;
    timezone: USTimezonesValues;
    apartment: string;
  };
  pdrp_opt_in: boolean;
  hcp_status: HCPStatus;
  hcp_view_status: HCPViewActionTaken;
  source_of_awareness: SourceOfAwareness;
  zip_code_state: ZipCode;
}

export interface HCPRequestModel extends LeadRequestModel {
  health_care_provider: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    city: string;
    speciality: string;
    office_address: string;
    license_number: string;
    npi_number: string;
    insurance: string;
    full_name: string;
    zip_code: string;
    timezone: string;
    apartment: string;
  };
  pdrp_opt_in: boolean;
  hcp_status: HCPStatus;
  source_of_awareness: SourceOfAwareness;
}

export interface HCPErrorModel extends LeadErrorModel {
  health_care_provider: {
    first_name: ErrorType;
    last_name: ErrorType;
    email: ErrorType;
    phone: ErrorType;
    city: ErrorType;
    speciality: ErrorType;
    office_address: ErrorType;
    license_number: ErrorType;
    npi_number: ErrorType;
    insurance: ErrorType;
    full_name: ErrorType;
    zip_code: ErrorType;
    timezone: ErrorType;
    age: ErrorType;
    apartment: ErrorType;
  };
  hcp_status: ErrorType;
  source_of_awareness: {
    other: ErrorType;
  };
}

export interface SourceOfAwareness {
  internet_search: SourceOfAwarenessItem;
  other: SourceOfAwarenessItem;
  tv_ad: SourceOfAwarenessItem;
  waiting_room_tv_ad: SourceOfAwarenessItem;
  word_of_mouth: SourceOfAwarenessItem;
}

export interface SourceOfAwarenessItem {
  checked: boolean;
  order: number;
  required: boolean;
  value: string;
}
export interface IHCPFormProps {
  lead: HCPUpdateModel;
  leadErrors: HCPErrorModel;
  busy: boolean;
  onChange: (lead: HCPUpdateModel) => void;
  onErrorChange: (lead: HCPErrorModel) => void;
}
