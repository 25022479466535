import { formatDateForChatConversation } from './DateHelper';

export function filterByAttr<T, K extends keyof T>(arr: T[], attr: K): T[] {
  const result = arr.reduce((unique, arrObj) => {
    if (!unique.some((obj) => obj[attr] === arrObj[attr])) {
      unique.push(arrObj);
    }
    return unique;
  }, []);
  return result;
}

export function insertObjectsBetweenDates(arr) {
  let prevDate = null;
  let result = [];

  // Get the date of the first message in the array
  const firstMessageDate =
    arr.length > 0
      ? new Date(arr[0]?.joiningDate || arr[0]?.dateCreated).toISOString()
      : null;

  // Add the date of the first message to the result array if it exists
  if (firstMessageDate !== null) {
    result.push({
      dateSeparator: formatDateForChatConversation(firstMessageDate),
    });
  }

  for (const obj of arr) {
    let currentDate = null;

    if (obj.joiningDate) {
      currentDate = new Date(obj.joiningDate).setHours(0, 0, 0, 0);
    } else if (obj.dateCreated) {
      currentDate = new Date(obj.dateCreated).setHours(0, 0, 0, 0);
    }

    if (obj?.state?.body === '') {
      continue;
    }

    if (currentDate && prevDate !== null && prevDate !== currentDate) {
      result.push({
        dateSeparator: formatDateForChatConversation(
          new Date(currentDate).toISOString()
        ),
      });
    }

    result.push(obj);
    prevDate = currentDate;
  }
  // Remove the date separator from the end of the result array

  if (
    result.length > 0 &&
    result[result.length - 1].dateSeparator !== undefined
  ) {
    result.pop();
  }

  return result;
}
