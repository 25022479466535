import { RootEnv } from 'app/stores/config/CreateStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';
import { Materials } from '../../../models/CustomerModels';

export class Layout {
  materials: Materials;

  constructor() {
    makeAutoObservable(this);
  }

  async loadMaterials(): Promise<Materials | boolean> {
    try {
      const { customerService } = getEnv<RootEnv>();
      const { data } = await customerService.getCustomersMaterials();

      runInAction(() => {
        this.materials = data;
      });

      return this.materials;
    } catch (error) {
      return false;
    }
  }
}
