import {
  Chip,
  Collapse,
  Drawer,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { rgba } from 'polished';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { NavLink as RouterNavLink } from 'react-router-dom';
import styled from 'styled-components';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { observer } from 'mobx-react-lite';

import { MaterialsGroup, Materials } from 'app/models/CustomerModels';
import { useStores } from 'app/stores/config/UseStores';
import OstroLogo from 'assets/images/ostro-logo.png';
import { AccessLevel } from 'app/models/RouteModels';

import MaterialsDialog from './MaterialsDialog';
import analytics from 'app/services/analytics';

const Scrollbar = styled(PerfectScrollbar)`
  border-right: 0;
  background-color: #222;
`;

const List = styled(MuiList)`
  background-color: #222;
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.fontSizes.xl};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.palette.common.white};
  background-color: #222;
  font-family: ${(props) => props.theme.typography.fontFamily};
  height: 56px;

  svg {
    margin: 0 auto;
  }

  ${(props) => props.theme.breakpoints.up('sm')} {
    height: 64px;
  }
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  cursor: pointer;

  svg {
    color: ${(props) => props.theme.palette.common.white};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  &.active {
    background-color: #333;

    span {
      color: ${(props) => props.theme.palette.grey['100']};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.palette.common.white};
    font-size: ${(props) => props.theme.fontSizes.md};
    padding: 0 16px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.palette.common.white, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.palette.common.white, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(12)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.palette.common.white, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.palette.common.white, 0.9)};
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.palette.common.white};
  span {
    font-size: ${(props) => props.theme.fontSizes.md};
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const SidebarHeader = styled(Typography)`
  color: ${(props) => props.theme.palette.common.white};
  padding: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const SidebarBottom = styled.div`
  background-color: #222;
`;

const CategoryLink = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(1)}px;
  padding-bottom: ${(props) => props.theme.spacing(1)}px;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    color: #6918d0;
  }

  svg {
    font-size: 20px;
    padding-right: 8px;
  }
`;

const CategoryTextLink = styled(ListItemText)`
  margin: 0;
  span {
    color: #ffffff;

    font-size: ${(props) => props.theme.fontSizes.md};
    text-decoration: underline;

    &:hover {
      color: #6918d0;
    }
  }
`;

const StyledChip = styled(Chip)`
  background-color: #ffffff;
  span {
    color: #000 !important;
  },
}
`;

export const SidebarCategory = ({
  name,
  icon,
  isOpen,
  isCollapsable,
  activeClassName,
  counter,
  ...rest
}) => {
  return (
    // @ts-ignore
    <Category {...rest}>
      {icon}
      <CategoryText {...rest}>{name}</CategoryText>
      {!!counter && (
        <StyledChip data-testid="counter-chip" label={counter} size="small" />
      )}
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess {...rest} />
        )
      ) : null}
    </Category>
  );
};

const SidebarLink = ({ name, to }) => {
  return (
    // TODO: fix types
    // @ts-ignore
    <Link button dense exact="true" to={to}>
      <LinkText>{name} </LinkText>
    </Link>
  );
};

interface IProps {
  routes: any;
  PaperProps: any;
  open?: boolean;
  onClose?: Function;
}

const Sidebar = ({ routes, PaperProps, open, onClose }: IProps) => {
  const [routesState, setRoutesState] = React.useState({});
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedMaterials, setSelectedMaterials] =
    React.useState<MaterialsGroup | null>(null);
  const [materials, setMaterials] = React.useState<Materials>(null);

  const {
    uiStores: { layoutStore },
    dataStores: { authStore },
  } = useStores();

  let role = AccessLevel.Public;

  const toggle = (index) => {
    setRoutesState({
      ...routesState,
      [index]: !routesState[index],
    });
  };

  React.useEffect(() => {
    if (authStore.isAuth && authStore.isConcierge) {
      (async () => {
        const res = await layoutStore.loadMaterials();
        if (typeof res !== 'boolean') setMaterials(res);
      })();
    }
  }, [authStore.authData?.access]);

  React.useEffect(() => {
    /* Open collapse element that matches current url */
    if (routes) {
      const pathName = location.pathname;
      routes.forEach((route, index) => {
        setRoutesState({
          ...routesState,
          [index]: pathName.startsWith(route.path),
        });
      });
    }
  }, [routes]);

  return (
    <>
      <Drawer variant="permanent" PaperProps={PaperProps} open={open}>
        <Scrollbar>
          <List disablePadding>
            {/* TODO: fix types */}
            {/* @ts-ignore */}
            <Brand>
              <img width="162" height="41" src={OstroLogo} alt="Ostro Logo" />
            </Brand>
            <Items>
              {routes?.map((category, index) => (
                <React.Fragment key={index}>
                  {category.header ? (
                    <SidebarHeader variant="caption">
                      {category.header}
                    </SidebarHeader>
                  ) : null}

                  {category.children ? (
                    <React.Fragment key={index}>
                      <SidebarCategory
                        isOpen={!routesState[index]}
                        isCollapsable={true}
                        name={category.id}
                        icon={category.icon}
                        activeClassName="active"
                        button={true}
                        onClick={() => toggle(index)}
                        counter={null}
                      />

                      <Collapse
                        in={routesState[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map((route, index) => (
                          <SidebarLink
                            key={index}
                            name={route.name}
                            to={route.path}
                          />
                        ))}
                      </Collapse>
                    </React.Fragment>
                  ) : category.access.includes(role) ||
                    (category.access.length === 1 &&
                      category.access.includes(AccessLevel.Auth)) ||
                    (category.access.includes(AccessLevel.Auth) &&
                      category.access.includes(
                        authStore.authData?.profile_type
                      )) ? (
                    <SidebarCategory
                      isOpen={false}
                      isCollapsable={false}
                      name={category.id}
                      to={category.path}
                      activeClassName="active"
                      component={RouterNavLink}
                      icon={category.icon}
                      onClick={onClose}
                      counter={category?.counter}
                      exact
                    />
                  ) : null}
                </React.Fragment>
              ))}
            </Items>
          </List>
        </Scrollbar>
        <SidebarBottom>
          <Scrollbar>
            <Items>
              {materials?.groups?.map((item, index) => (
                // TODO: fix types
                // @ts-ignore
                <CategoryLink
                  key={index}
                  onClick={() => {
                    analytics.captureQuickLinkGroupClicked(item);
                    setSelectedMaterials(item);
                    setOpenModal(true);
                  }}
                >
                  <CategoryTextLink>{item.group_name}</CategoryTextLink>
                </CategoryLink>
              ))}

              {materials?.general?.map((item, index) => (
                // TODO: fix types
                // @ts-ignore
                <CategoryLink
                  key={index + 100}
                  onClick={() => {
                    analytics.captureQuickLinkClicked(item);
                    if (item.link || item.file) {
                      window.open(item.link || item.file, '_blank').focus();
                    }
                  }}
                >
                  <OpenInNewIcon />
                  <CategoryTextLink>{item.name}</CategoryTextLink>
                </CategoryLink>
              ))}
            </Items>
          </Scrollbar>
        </SidebarBottom>
      </Drawer>
      <MaterialsDialog
        open={openModal}
        materials={selectedMaterials}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default observer(Sidebar);
