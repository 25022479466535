import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';
import {
  ConciergeResult,
  ImageResponse,
  SoundNotificationsResult,
  UserProfile,
} from 'app/models/UserModels';

import { RawPagingResult } from '../models/CommonModels';

export default class UserService {
  async getConcierges(
    params?: string | undefined
  ): Promise<AxiosResponse<RawPagingResult<ConciergeResult>>> {
    return await Axios.get(`/concierges/twilio-ids/?${params ? params : ''}`);
  }

  async getConciergeProfile(): Promise<AxiosResponse> {
    return await Axios.get(`/concierges/profile/`);
  }

  async updateConciergeProfile(data: UserProfile): Promise<AxiosResponse> {
    return await Axios.patch(`/concierges/profile/`, data);
  }

  async uploadSettingsImage(
    img: FormData
  ): Promise<AxiosResponse<ImageResponse>> {
    return await Axios.post(`/upload/public-image/`, img);
  }

  async getSoundNotifications(): Promise<
    AxiosResponse<RawPagingResult<SoundNotificationsResult>>
  > {
    return await Axios.get(`/concierges/concierge-sound-notifications/`);
  }
}
