import { Consumer } from '../Consumer/Consumer';
import { Consumers } from '../Consumer/Consumers';
import { HCP } from '../HCP/HCP';
import { LeadQuestions } from '../Lead/LeadQuestions';
import { HCPs } from '../HCP/HCPs';
import { LeadCustomers } from './LeadCustomers';
import { HappituDataStore } from '../HappituDataStore';

export class LeadStore {
  consumerStore: Consumer;
  consumersStore: Consumers;
  leadQuestionsStore: LeadQuestions;
  hcpStore: HCP;
  hcpsStore: HCPs;
  leadCustomers: LeadCustomers;
  happituDataStore: HappituDataStore;

  constructor() {
    this.consumerStore = new Consumer();
    this.consumersStore = new Consumers();
    this.leadQuestionsStore = new LeadQuestions();
    this.hcpStore = new HCP();
    this.hcpsStore = new HCPs();
    this.leadCustomers = new LeadCustomers();
    this.happituDataStore = new HappituDataStore();
  }
}
