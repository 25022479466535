import { makeAutoObservable } from 'mobx';
import { createModelSchema, identifier, primitive } from 'serializr';

import { ConsumerType } from './LeadsModels';

export class Chat {
  conversation_sid: string;
  blocked: boolean = false;
  delivery_failed: boolean = false;
  friendly_name: string;
  survey_uuid: string;
  unread_user_messages_count: number = 0;
  last_message_dt: string = new Date().toISOString();
  product_name: string;
  test_only: boolean;
  consumer_type: ConsumerType;
  logo: string;

  constructor() {
    makeAutoObservable(this);
  }

  setBlocked(value: boolean) {
    this.blocked = value;
  }

  resetMessagesCount() {
    this.unread_user_messages_count = 0;
  }

  incrementMessagesCount() {
    this.unread_user_messages_count++;
  }

  setLastMessageDate(date: string) {
    this.last_message_dt = date;
  }
}

createModelSchema(Chat, {
  conversation_sid: identifier(),
  blocked: primitive(),
  delivery_failed: primitive(),
  friendly_name: primitive(),
  last_message_dt: primitive(),
  survey_uuid: primitive(),
  unread_user_messages_count: primitive(),
  product_name: primitive(),
  test_only: primitive(),
  consumer_type: primitive(),
  logo: primitive(),
});
