import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { StylesProvider } from '@material-ui/styles';
import * as React from 'react';
import { ThemeProvider } from 'styled-components';

import Routes from './routes/Routes';
import theme from './theme/Theme';

// render react DOM
export const App = () => (
  <StylesProvider injectFirst>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </MuiThemeProvider>
    </MuiPickersUtilsProvider>
  </StylesProvider>
);
