import {
  format,
  formatDistanceToNow,
  isSameDay,
  isSameWeek,
  isSameYear,
  parseISO,
  parseJSON,
  startOfDay,
} from 'date-fns';
import { formatInTimeZone, format as formatTz } from 'date-fns-tz';

export function getUSDateTimeStr(date: string): string {
  return date ? format(parseISO(date), 'MM-dd-yyyy hh:mm a zzz') : '-';
}

export function getTimeDurationStr(time: string): string {
  const timeArr = time.split(':'); //sould be format '01:30:25'
  if (timeArr.length !== 3) {
    //sould be format '01:30:25' and arr ['01', '30', '25']
    return time;
  }
  return `${+timeArr[0] * 60 + +timeArr[1]}m ${timeArr[2]}s`; // returns for instance: input '01:05:35' -> output '65m 35s'
}

export function converDateToUTCByTimezone(
  rawDateTime: Date,
  targetTimezone: string
): Date {
  if (!rawDateTime || !targetTimezone) {
    return null;
  }

  //remove timezone
  //let noTimezoneDateTime = format(rawDateTime, 'MM/dd/yyyy hh:mm aaa');

  //convert to UTC based on specified timezone
  // let utc = parseFromTimeZone(noTimezoneDateTime, {
  //   timeZone: targetTimezone,
  // }).toISOString();

  let utc = formatTz(rawDateTime, 'yyyy-MM-dd HH:mm:ss zzz', {
    timeZone: targetTimezone,
  });

  return new Date(utc);
}

export function getTimeDistance(date: string): string {
  return date ? formatDistanceToNow(parseISO(date)) : '-';
}

export function secondsToTime(secs: number) {
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  const obj = {
    h: hours >= 10 ? hours : '0' + hours,
    m: minutes >= 10 ? minutes : '0' + minutes,
    s: seconds >= 10 ? seconds : '0' + seconds,
  };
  return obj;
}

export function formatDateForChatList(date: string) {
  const today = startOfDay(new Date());
  const parsedDate = new Date(date);

  if (isSameDay(parsedDate, today)) {
    return format(parsedDate, 'h:mm a');
  } else if (isSameWeek(parsedDate, today)) {
    return format(parsedDate, 'EEE');
  } else if (isSameYear(parsedDate, today)) {
    return format(parsedDate, 'MMM d');
  } else {
    return format(parsedDate, 'MMM d, yyyy');
  }
}

export function formatDateForChatConversation(date: string) {
  const today = new Date();
  const parsedDate = new Date(date);

  if (isSameDay(parsedDate, today)) {
    return 'Today';
  } else if (isSameWeek(parsedDate, today)) {
    return format(parsedDate, 'EEEE');
  } else if (isSameYear(parsedDate, today)) {
    return format(parsedDate, 'd MMMM');
  } else {
    return format(parsedDate, 'd MMMM yyyy');
  }
}

export function utcToZonedTimeStr(dt: string, tz: string) {
  return dt
    ? formatTz(parseISO(dt), 'yyyy-MM-dd HH:mm:ss zzz', { timeZone: tz })
    : '-';
}

/**
 * Tries to reformat a given datetime string into the given format, while keeping the timezone.
 * This assumes that the timezone is present at the end of the given string, either as `-00:00` or `Z`.
 * If no timezone is present, UTC will be assumed.
 * @param isoString A date string that follows the ISO 8601 format
 * @param formatString The desired format of the output. Go to `date-fns` docs for reference on the tokens.
 * @returns The date string re-formatted in the provided format, or `null` if the string is not properly formatted
 * @example
 * formatISOStringToSameTimezone(
 *   '2024-09-20 11:33:32.606923-05:00',
 *   'MM/dd/yyyy hh:mm aa zzz'
 * ) // returns '09/20/2024 11:33 AM GMT-5'
 */
export function formatISOStringToSameTimezone(
  isoString: string,
  formatString: string
) {
  let formattedDate = null;

  try {
    let timeZone = isoString.match(/[+-]\d{2}:\d{2}|Z$/)?.[0];

    if (timeZone?.toLowerCase?.() === 'z') {
      timeZone = 'UTC';
    }

    const date = parseJSON(isoString);
    formattedDate = formatInTimeZone(date, timeZone, formatString);
  } catch (error) {
    console.warn(
      'Could not properly format the given date: ',
      isoString,
      'in the given format: ',
      formatString
    );
    console.warn(error);
  }

  return formattedDate;
}
