import {
  smsChats as smsChatRouteItem,
  lead as leadChatRouteItem,
  hcp as hcpChatRouteItem,
} from 'app/routes';

import { PagingResult } from './CommonModels';
import { ConsumerType } from './LeadsModels';

export type ActiveConversationSid = string;
export interface ConciergeChatToken {
  identity: string;
  token: string;
}

export interface IConversation {
  activeConversationSid: ActiveConversationSid;
  activeConversationJoinHistory: JoiningIdentityMsg[];
  conversations: any[];
  conversationsReady: boolean;
  status: string;
  statusString: string;
}

export interface LeadChatModel extends ConciergeChatToken {
  sid: string;
  blocked: boolean;
  survey_uuid: string;
  uuid: string;
  friendly_name: string;
  last_message_dt: string;
  unread_user_messages_count: number;
  delivery_failed: boolean;
  logo: string;
}

export interface UnreadMessagesCounter {
  sms_chats: number;
  web_chats: WebchatUnreadMessagesCounter;
}

export interface WebchatUnreadMessagesCounter {
  all: number;
  in_progres: number;
  new: number;
}

export enum MessageDirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing',
  IncomingVA = 'incoming_va',
  OutgoingVA = 'outgoing_va',
}

export enum MessageStatus {
  Sent = 'sent',
  Delivered = 'delivered',
  Failed = 'failed',
  Read = 'read',
  Undelivered = 'undelivered',
  Queued = 'queued',
}

export enum WebConversationStage {
  New = 10,
  InProgress = 20,
  Archived = 30,
}

export class SmsConversationsResult extends PagingResult<SmsConversation> {}

export interface SmsConversation {
  survey_uuid: string;
  blocked: boolean;
  uuid: string;
  conversation_sid: string;
  friendly_name: string;
  last_message_dt: string;
  unread_user_messages_count: number;
  delivery_failed: boolean;
  logo: string;
}

export class WebConversationsResult extends PagingResult<WebConversation> {}

export interface WebConversation {
  uuid: string;
  conversation_sid: string;
  chat_service_sid: string;
  stage: WebConversationStage;
  blocked: boolean;
  contact_info: string;
  friendly_name: string;
  last_consumer_message_dt: string;
  last_message_dt: string;
  unread_user_messages_count: number;
  delivery_failed: boolean;
  product_name: string;
  token_user: string;
  test_only: boolean;
  consumer_type: ConsumerType;
  logo: string;
  user_online: boolean;
  condition_uuid: string;
}

export interface WebConversationDetails extends WebConversation {
  user_online: boolean;
  active_chat_screen: WebChatActiveScreen | null;
  join_history: JoiningIdentityMsg[];
  special_answers: FormattedAnswer[];
  condition_name: string;
  local_time: string;
  imageSrc: string | Buffer;
  has_profile: boolean;
  send_telemedicine_link: boolean;
  patient_email: string;
  survey_uuid: string;
  logo: string;
  auto_archive_disabled: boolean;
}

export interface FormattedAnswer {
  message_type: string;
  message_id: string;
  skipped: boolean;
  answer: string;
}
export interface JoiningIdentityMsg {
  joiningDate: Date;
  identityName: string;
}
export interface OptInIdentityMsg {
  isOptedIn: boolean;
  identityName: string;
}

export enum ConversationActionType {
  Block = 'block_conversation',
  ProceedBlocking = 'proceed_blocking',
  Unblock = 'unblock',
  Archive = 'archive_conversation',
  NewMessage = 'new_message',
  DeliveryFailed = 'delivery_failed',
  SetMessagesRead = 'set_messages_read',
  WebConversationSpecialAnswer = 'new_web_conversation_answer',
  DeliverySuccess = 'delivery_success',
  UpdateSMSOrdering = 'update_sms_ordering',
  UnblockConversation = 'unblock_conversation',
  UnreadMessagesCount = 'unread_message_counter',
  ActiveChatScreen = 'active_chat_screen',
  UserOffline = 'user_offline',
  UserOnline = 'user_online',
  ChangeStatusFromNewToInProgres = 'change_status_from_new_to_in_progres',
}

export interface WSMsg {
  source: 'web_conversation' | 'survey_conversation';
  action: ConversationActionType;
  conversation_sid: string; // leaving it to avoid big refactoring
  chat_uuid?: string;

  notification_type?: string;
  data:
    | WebConversation
    | SmsConversation
    | NewSurveyNotification
    | WebConversationNewSpecialMsg
    | ActiveChatScreenData;
}

export interface WebConversationNewSpecialMsg {
  answer: string;
  skipped: boolean;
  conversation_sid: string;
}

export interface NewSurveyNotification {
  survey_uuid: string;
  web_chat_uuid: string | null;
}

export const DefaultConversations = {
  activeConversationSid: '',
  activeConversationJoinHistory: [],
  conversations: [],
  conversationsReady: false,
  status: '',
  statusString: 'Disconnected.',
};

export enum ChatError {
  MessagesLoadingError = "The chat messages haven't been loaded. Please try to reload the page.",
  SessionExpired = 'Chat session has been expired. Please reload the page and start the chat again.',
}

export enum ChatDocumentsError {
  DocumentsLoadingError = "The chat documents haven't been loaded.",
  ToggleDocumentError = 'The document update error.',
}

export interface ChatDocument {
  uuid: string;
  document_name: string;
  selected: boolean;
}

export interface ProfileLinkModel {
  redirect_url: string;
  profile_type: string;
  profile_uuid: string;
}
export interface StopMessageDetails {
  stop_message: string;
  stop_sending_status: StopMessageStatus;
  unsubscribed: boolean;
}

export enum StopMessageStatus {
  NotSend = 10,
  OnlyFirstMessage = 20,
  EachMessage = 30,
}

export enum TypeMessage {
  VirtualAssistant = 'v3_history_va',
  VirtualAssistantForm = 'v3_history_va_form',
}

export enum ValueRoleType {
  FirstName = 'first_name',
  LastName = 'last_name',
  ContactInfo = 'contact_info',
  PersonalPhone = 'personal_phone',
  Email = 'email',
  Age = 'age',
  BirthDate = 'birth_date',
  Consent = 'consent',
  NpiNumber = 'npi',
  ZipCode = 'zip_code',
}

export const possibleChatRoutes = [
  smsChatRouteItem.path,
  leadChatRouteItem.path,
  hcpChatRouteItem.path,
];

export interface ActiveChatScreenData {
  active_chat_screen: WebChatActiveScreen;
}

export enum WebChatActiveScreen {
  HELP_CENTER = 'help_center',
  FAQ_SEARCH = 'faq_search',
  FAQ_PAGE = 'faq_page',
  CHAT_BOT = 'virtual_assistant',
  TERMS_OF_USE = 'terms_of_use',
  TERMS_OF_USE_HC = 'terms_of_use',
  PRIVACY_POLICY = 'privacy_policy',
  PRIVACY_POLICY_HC = 'privacy_policy',
  NAVIGATOR_DEFINITION = 'navigator_definition',
  CUSTOM_CONTENT = 'other',
  CHAT = 'live_chat',
}

export interface ChatsFiltersModel {
  stage?: WebConversationStage;
  page?: number;
  search?: string | '';
  concierge_uuid?: string | number;
  created_dt_before?: Date | null;
  created_dt_after?: Date | null;
}
