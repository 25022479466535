import { makeAutoObservable, observable, reaction, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import { deserialize } from 'serializr';
import { Websocket, WebsocketEvents } from 'websocket-ts/lib';
import * as Sentry from '@sentry/browser';

import { Chat } from 'app/models/Chat';
import {
  ActiveConversationSid,
  ConversationActionType,
  SmsConversation,
  SmsConversationsResult,
  UnreadMessagesCounter,
} from 'app/models/ChatModels';
import { parsePagingResult } from 'app/models/CommonModels';
import { TelemedicineUrl } from 'app/models/Lead';
import { RootEnv } from 'app/stores/config/CreateStore';
import { sortChatsByLastMessageDate } from 'app/utils/SortingHeplers';
import { filterByAttr } from 'app/utils/ArrayHelpers';

import { WSMsg } from '../../../models/ChatModels';
import RootStore from '../../RootStore';
import ChatsStore from './Chats';
import { SmsChat } from './SmsChat';

export class SmsChats {
  pendingActiveConversation: SmsConversation = null;
  activeConversationSid?: string;

  smsChatStore = new SmsChat();
  conversations = observable.array<Chat>([], { deep: false });

  wsNotificationMessage: WSMsg = null;
  typedMessages: Map<ActiveConversationSid, string> = new Map();
  busyStatus: '' | 'loadingRoster' = 'loadingRoster';
  rawData: SmsConversationsResult;
  tempNewConv: SmsConversation = null;
  tempOpenedChat: boolean = false;
  telemedUrl: TelemedicineUrl[] = [];
  unreadMsgCount: number;

  //private
  private webSocket: Websocket;
  private timerId;

  get isBusy(): boolean {
    return this.busyStatus === 'loadingRoster';
  }

  get hasUnread(): boolean {
    return this.conversations.some((wc) => wc.unread_user_messages_count > 0);
  }

  constructor(private chatsStore: ChatsStore) {
    makeAutoObservable(this, {
      conversations: observable.shallow,
    });
    const boundCallback = this.onWsMessage.bind(this);
    reaction(
      () => chatsStore.ws,
      (newWS, prevWS) => {
        if (prevWS) {
          prevWS.removeEventListener(WebsocketEvents.message, boundCallback);
          this.webSocket = null;
        }
        if (newWS) {
          newWS.addEventListener(WebsocketEvents.message, boundCallback);
          this.webSocket = newWS;
        }
      }
    );
  }

  private getConversationBySid(sid: string) {
    return this.conversations.find((chat) => chat.conversation_sid === sid);
  }

  async loadSmsConversations(sid?: string, params?: string) {
    try {
      this.busyStatus = 'loadingRoster';
      const { chatService } = getEnv<RootEnv>();
      const { data } = await chatService.loadSmsConversations(params);
      const rawData: SmsConversationsResult = parsePagingResult(data);

      const chats = deserialize(Chat, data.results).filter((item) => {
        if (item?.conversation_sid) {
          return item;
        }
      });

      runInAction(() => {
        const filteredArray = filterByAttr(
          [...this.conversations.concat(chats)],
          'conversation_sid'
        );
        this.conversations.replace(filteredArray);
        this.rawData = rawData;
        this.busyStatus = '';
        if (sid) {
          this.setActiveChat(sid);
        }
      });
    } catch (error) {
      throw Error(error);
    }
  }

  cleanUpConversations() {
    this.conversations.clear();
  }

  closeWSNotifications() {
    if (!this.webSocket) {
      const { notificationsService } = getEnv<RootEnv>();
      this.webSocket = notificationsService.ws;
    }

    this.webSocket?.close();
  }

  setPendingConversation(conv: SmsConversation) {
    this.pendingActiveConversation = conv;
  }

  setActiveChat(sid: string, unblockRequired = true) {
    const rootStore = getRoot<RootStore>();
    const join_history =
      rootStore.dataStores.leadStore.consumerStore.shortLeadInfo
        ?.join_history || [];

    this.smsChatStore.setJoinHistory(join_history);
    const chat = this.getConversationBySid(sid);

    if (chat) {
      chat.setBlocked(true);
    }

    unblockRequired && this.unblockChat();

    this.blockChat(sid);

    this.pendingActiveConversation = null;
    this.activeConversationSid = sid;
  }

  unblockChat(sid?: string) {
    if (this.timerId) {
      clearInterval(this.timerId);
      this.timerId = null;
    }
    const unblockingSid = sid || this.activeConversationSid;
    if (unblockingSid) {
      this.sendWsActionMsg(unblockingSid, ConversationActionType.Unblock);
      const chat = this.getConversationBySid(unblockingSid);

      if (chat) {
        chat.setBlocked(false);
        this.activeConversationSid = '';
      }
    }
  }

  blockChat(sid: string) {
    this.timerId = setInterval(
      () => this.sendWsActionMsg(sid, ConversationActionType.ProceedBlocking),
      30000
    );
  }

  setAllMsgsAsRead(sid: string) {
    this.sendWsActionMsg(sid, ConversationActionType.SetMessagesRead);
    const chat = this.getConversationBySid(sid);

    if (chat) {
      chat.resetMessagesCount();
    }
  }

  async loadConversation(sid: string) {
    const conversation =
      await this.chatsStore.conversationalClient.getConversationBySid(sid);
    this.smsChatStore.dispose();
    await this.smsChatStore.setConversation(conversation);
  }

  private toggleChat(sid: string, blocked: boolean) {
    const chat = this.getConversationBySid(sid);
    if (!chat) return;

    chat.setBlocked(blocked);
  }

  private onWsMessage(_i, ev) {
    const msg: WSMsg = JSON.parse(ev.data);

    if (msg?.source === 'survey_conversation') {
      this.wsNotificationMessage = msg;
      switch (this.wsNotificationMessage.action) {
        case ConversationActionType.Block:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, true);
          break;
        case ConversationActionType.Unblock:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, false);
          break;
        case ConversationActionType.NewMessage:
        case ConversationActionType.UpdateSMSOrdering:
          this.updateChatsOrdering(msg);
          break;
        case ConversationActionType.DeliveryFailed:
          this.handleDeliveryStatus(msg, false);
          break;
        case ConversationActionType.DeliverySuccess:
          this.handleDeliveryStatus(msg, true);
          break;
        case ConversationActionType.UnblockConversation:
          this.toggleChat(this.wsNotificationMessage.conversation_sid, false);
          this.updateChatsOrdering(msg);
      }
    } else {
      this.wsNotificationMessage = msg;
      if (
        this.wsNotificationMessage.action ===
        ConversationActionType.UnreadMessagesCount
      ) {
        this.setUnreadMessageCount(msg);
      }
    }
  }

  private sendWsActionMsg(sid: string, action: ConversationActionType) {
    const {
      dataStores: { authStore },
    } = getRoot<RootStore>();

    this.webSocket.send(
      JSON.stringify({
        action: 'survey_conversation_' + action,
        conversation_sid: sid,
        cdp_auth: authStore.authData.access,
      })
    );
  }

  private handleNewMsg(msg: WSMsg) {
    const { conversation_sid } = msg.data as SmsConversation;
    const chat = this.getConversationBySid(conversation_sid);

    if (chat) {
      chat.incrementMessagesCount();
      chat.setLastMessageDate(new Date().toISOString());
    }
  }

  setUnreadMessageCount(msg) {
    const {
      dataStores: { userStore },
    } = getRoot<RootStore>();

    runInAction(() => {
      const counterData = msg.data.find(
        (obj) => obj[userStore.userUUID]
      ) as UnreadMessagesCounter;
      if (counterData) {
        this.unreadMsgCount = counterData[userStore.userUUID].sms_chats;
      }
    });
  }

  private handleDeliveryStatus(msg: WSMsg, delivered: boolean) {
    const conversation_sid = msg.conversation_sid;
    const chat = this.getConversationBySid(conversation_sid);

    if (chat) {
      const copyResults = [...this.conversations];
      copyResults.splice(this.conversations.indexOf(chat), 1, {
        ...chat,
        delivery_failed: !delivered,
      } as Chat);
      this.conversations = [...copyResults] as any;
    }
  }

  get activeTypedMsg() {
    return this.typedMessages.get(this.activeConversationSid);
  }

  deleteActiveTypedMsg() {
    this.typedMessages.delete(this.activeConversationSid);
  }

  async fetchTelemedicineUrl(uuid: string) {
    try {
      const { leadService } = getEnv<RootEnv>();
      const { data } = await leadService.getTelemedicineUrl(uuid);

      runInAction(() => (this.telemedUrl = data));
    } catch (error) {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();
      notifierStore.notify(error?.message);
    }
  }

  updateChatsOrdering(msg: WSMsg) {
    const smsConversationMsg = msg.data as SmsConversation;
    const deserializeConv = deserialize(Chat, smsConversationMsg);

    const chat = this.getConversationBySid(smsConversationMsg.conversation_sid);

    if (chat) {
      const copyResults = [...this.conversations];
      copyResults.splice(this.conversations.indexOf(chat), 1, deserializeConv);
      this.conversations = [...copyResults].sort(
        sortChatsByLastMessageDate
      ) as any;
    } else {
      runInAction(() => {
        this.conversations = [deserializeConv, ...this.conversations] as any;
      });
    }
  }

  sortChatsOnConciergeMsg() {
    const chat = this.getConversationBySid(this.activeConversationSid);
    if (chat) {
      chat.setLastMessageDate(new Date().toISOString());
      this.conversations = [...this.conversations].sort(
        sortChatsByLastMessageDate
      ) as any;
    }
  }
}
