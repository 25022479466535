import React, { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import {
  BrowserRouter as Router,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import AuthLayout from 'app/components/layouts/Auth';
import { useStores } from 'app/stores/config/UseStores';

import { authRoutes, dashboardRoutes } from './index';
import DashboardLayout from '../components/layouts/Dashboard';
import analytics from 'app/services/analytics';

const SentryRoute = Sentry.withSentryRouting(Route);

type Props = {
  children: React.ReactNode;
};

const CheckAuth = withRouter(
  observer(({ history, children }: RouteComponentProps<any> & Props) => {
    const [busy, setBusy] = useState(false);

    let disposeHistoryOnChange;
    const {
      dataStores: { authStore },
    } = useStores();

    const handleLocationChange = async (location) => {
      setBusy(true);
      if (!authStore.isAuth) {
        await authStore.refresh();
      } else {
        authStore.sync();

        (location.pathname === '/' || location.pathname === '/auth') &&
          history.replace('leads');
      }
      setBusy(false);
    };

    useEffect(() => {
      handleLocationChange(history.location);
      disposeHistoryOnChange = history.listen(handleLocationChange);

      return disposeHistoryOnChange();
    }, [false]);

    return busy ? <>Loading...</> : <>{children}</>;
  })
);

const ChildRoutes = ({ layout: Layout, routes }) => {
  const SuspenseFallback = <>Loading...</>;

  return (
    <Layout>
      <Switch>
        {routes.map((category, index) =>
          category.children ? (
            // Route item with children
            category.children.map((route, index) => (
              <SentryRoute
                key={index}
                path={route.path}
                exact
                render={() => {
                  return (
                    <React.Suspense fallback={SuspenseFallback}>
                      <route.component></route.component>
                    </React.Suspense>
                  );
                }}
              />
            ))
          ) : (
            // Route item without children
            <SentryRoute
              key={index}
              path={category.path}
              exact
              render={() => {
                return (
                  <React.Suspense fallback={SuspenseFallback}>
                    <category.component></category.component>
                  </React.Suspense>
                );
              }}
            />
          )
        )}
      </Switch>
    </Layout>
  );
};

const Routes = () => {
  return (
    <Router>
      <CheckAuth>
        <Switch>
          {/* Auth routes */}
          <SentryRoute
            path="/auth*"
            exact
            component={() => (
              <ChildRoutes layout={AuthLayout} routes={authRoutes} />
            )}
          />

          {/* Dashboard routes */}
          <SentryRoute
            path="/*"
            exact
            component={() => (
              <ChildRoutes layout={DashboardLayout} routes={dashboardRoutes} />
            )}
          />
        </Switch>
      </CheckAuth>
    </Router>
  );
};

export default Routes;
