import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';
import {
  LeadChatModel,
  ConciergeChatToken,
  WebConversationStage,
  WebConversationsResult,
  ProfileLinkModel,
  SmsConversationsResult,
  WebConversation,
} from 'app/models/ChatModels';
import { PreviewUploadModel } from 'app/models/FileModel';
import { RawPagingResult } from 'app/models/CommonModels';

import { WebConversationDetails, ChatDocument } from '../models/ChatModels';

export default class ChatService {
  async getProfileLink(uuid: string): Promise<AxiosResponse<ProfileLinkModel>> {
    return await Axios.get(`/conversations/web/${uuid}/profile-link/`);
  }

  async getChatsToken(): Promise<AxiosResponse<ConciergeChatToken>> {
    return await Axios.get(`/surveys/conversations/token/`);
  }

  async initChat(uuid: string): Promise<AxiosResponse<LeadChatModel>> {
    return await Axios.post(`/surveys/${uuid}/conversation/`);
  }

  async loadSmsConversations(
    params?: string
  ): Promise<AxiosResponse<RawPagingResult<SmsConversationsResult>>> {
    return await Axios.get(`/surveys/conversations/?${params ? params : ''}`);
  }

  async loadWebConversations(
    params?: string | undefined
  ): Promise<AxiosResponse<RawPagingResult<WebConversationsResult>>> {
    return await Axios.get(`/conversations/web/?${params ? params : ''}`);
  }

  async getWebConversationUuid(
    uuid: string
  ): Promise<AxiosResponse<WebConversationDetails>> {
    return await Axios.get(`/conversations/web/${uuid}/`);
  }

  async getWebConversationBySID(
    sid: string
  ): Promise<AxiosResponse<WebConversationDetails>> {
    return await Axios.post(`/conversations/web-by-sid/${sid}`);
  }

  async startWebConversationByUuid(
    uuid: string
  ): Promise<AxiosResponse<WebConversation>> {
    return await Axios.post(`/conversations/web/${uuid}/start/`);
  }

  async startSmsConversationBySid(sid: string): Promise<AxiosResponse> {
    return await Axios.post(`surveys/conversations/${sid}/start/`);
  }

  async editWebChat(
    uuid: string,
    data: unknown
  ): Promise<AxiosResponse<WebConversationDetails>> {
    return await Axios.patch(`/conversations/web/${uuid}/`, data);
  }

  //obsolated
  async changeStageWebConversation(
    uuid: string,
    stage: WebConversationStage
  ): Promise<AxiosResponse<any>> {
    return await Axios.patch(`/conversations/web/${uuid}/`, {
      stage: stage,
    });
  }

  async attachMaterialToConversation(
    conversation: string,
    file: string
  ): Promise<AxiosResponse<PreviewUploadModel>> {
    return await Axios.post('/surveys/conversations/materials/', {
      conversation,
      file,
    });
  }

  async detachMaterialFromConversation(
    conversation_uuid: string
  ): Promise<AxiosResponse<any>> {
    return await Axios.delete(
      `/surveys/conversations/materials/${conversation_uuid}`
    );
  }

  async loadConversationDocuments(
    conversation_sid: string,
    isWebChat: boolean = false
  ): Promise<AxiosResponse<ChatDocument[]>> {
    return await Axios.get(
      `${
        isWebChat ? '/conversations/web/' : '/surveys/conversations/'
      }${conversation_sid}/documents/`
    );
  }

  async toggleConversationDocument(
    conversation_sid: string,
    document_uuid: string,
    isWebChat: boolean = false
  ): Promise<AxiosResponse> {
    return await Axios.patch(
      `${
        isWebChat ? '/conversations/web/' : '/surveys/conversations/'
      }${conversation_sid}/documents/${document_uuid}/`
    );
  }
}
