import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';

import { RootEnv } from 'app/stores/config/CreateStore';
import {
  HCPErrorModel,
  HCPModel,
  HCPStatus,
  HCPUpdateModel,
  parseHCPUpdate,
} from 'app/models/HCP';
import { HistoryResult } from 'app/models/HistoryModels';
import { getError as getErrorMsg } from 'app/models/ErrorModels';

const cursorParam = 'cursor';
export class HCP {
  lead: HCPModel;
  history: HistoryResult = null;
  constructor() {
    makeAutoObservable(this);
  }

  async loadHCP(
    uuid: string
  ): Promise<{ success: boolean; msg: string | object; status: HCPStatus }> {
    try {
      const { hcpService } = getEnv<RootEnv>();
      const { data } = await hcpService.getHCP(uuid);

      runInAction(() => {
        this.lead = data;
      });

      return { success: true, msg: '', status: data.hcp_status };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
        status: error?.response?.status,
      };
    }
  }

  async updateHCP(
    uuid: string,
    lead: HCPUpdateModel
  ): Promise<{
    success: boolean;
    msg: string;
    formErrors?: HCPErrorModel | null;
  }> {
    try {
      const { hcpService } = getEnv<RootEnv>();

      const { data } = await hcpService.updateHCP(uuid, parseHCPUpdate(lead));

      runInAction(() => {
        this.lead = data;
      });

      return { success: true, msg: 'Survey has been successfully updated.' };
    } catch (error) {
      return {
        success: false,
        msg: '',
        formErrors: error?.response?.data,
      };
    }
  }

  async loadHistory(
    luuid: string,
    params: string | undefined
  ): Promise<boolean> {
    try {
      const { hcpService } = getEnv<RootEnv>();
      const { data } = await hcpService.getHistory(luuid, params);

      runInAction(() => {
        if (data?.result) {
          this.history = {
            next_cursor: data.next_cursor,
            period: data.period,
            result: params?.includes(cursorParam)
              ? this.history.result.concat(data.result)
              : data.result,
          };
        } else {
          this.history.next_cursor = null;
        }
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async setLeadStatus(
    uuid: string,
    status: HCPStatus
  ): Promise<{ success: boolean; msg: string | object }> {
    try {
      const { hcpService } = getEnv<RootEnv>();
      await hcpService.updateLeadStatus(uuid, status);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    }
  }

  async reassignLead(
    uuid: string
  ): Promise<{ success: boolean; msg: string | object }> {
    try {
      const { hcpService } = getEnv<RootEnv>();
      await hcpService.reassignLead(uuid);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    }
  }
}
