import posthog from 'posthog-js';

import { AccessLevel } from 'app/models/RouteModels';

import { config } from '../config';

export const definePosthog = () => {
  //@ts-ignore
  if (!window.posthog) {
    Object.defineProperty(window, 'posthog', {
      value: posthog,
      configurable: true,
    });
  }
};

export const initialize = () => {
  posthog.init(config.POSTHOG_KEY, {
    api_host: config.POSTHOG_API_HOST,
    autocapture: false,
    persistence: 'localStorage',
  });
};

export interface IUserProps {
  name: string;
  id: string;
  uuid: string;
  role: AccessLevel;
}

export const identify = (userEmail: string, userProps: Partial<IUserProps>) => {
  posthog.identify(userEmail, { email: userEmail, ...userProps });
  // posthog.register(userProps);
};

export const captureEvent = (event: string, props: any) => {
  posthog.capture(event, props);
};

export const reset = () => {
  posthog.reset();
};

export default {
  initialize,
  identify,
  captureEvent,
  reset,
};

definePosthog();
