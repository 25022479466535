import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { HintVariant } from 'app/constants/HintVariant';

const wrapperStyles = createStyles({
  success: {
    backgroundColor: '#80C894',
  },
  error: {
    backgroundColor: '#F3AEA9',
  },
  info: {
    backgroundColor: '#F3F4F7',
  },
  warning: {
    backgroundColor: '#F79233',
  },
  content: {
    flexWrap: 'nowrap',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 10,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.1rem',
  },
});

export interface INotifier {
  open: boolean;
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof HintVariant;
}

interface IProps extends INotifier, WithStyles<typeof wrapperStyles> {}

const Notifier = withStyles(wrapperStyles)((props: IProps) => {
  const { open, className, message, onClose, variant, classes, ...other } =
    props;
  const Icon = HintVariant[variant];

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={10000}
      onClose={onClose}
    >
      <SnackbarContent
        className={clsx(classes[variant], classes.content, className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
        {...other}
      />
    </Snackbar>
  );
});

export default Notifier;
