import { PagingResult } from './CommonModels';
import { Customer } from '../models/Lead';

export class ConciergeResult extends PagingResult<BaseConciergeModel> {}
export class SoundNotificationsResult extends PagingResult<SoundNotifications> {}

export class BaseConciergeModel {
  uuid: string | number;
  twilio_id: string;
}

export const DefaultConcierge: BaseConciergeModel = {
  uuid: -1,
  twilio_id: 'All Navigators',
};

export const DefaultUserData: UserProfile = {
  uuid: '',
  first_name: '',
  profile_photo: '',
  email: '',
  sms_chat_sound_enable: true,
  sms_chat_sound_file: '',
  web_chat_sound_enable: true,
  web_chat_sound_file: '',
  web_chat_sound_file_url: '',
  sms_chat_sound_file_url: '',
  active_customers_ids: [],
  active_customers: [],
};

export interface UserProfile {
  uuid: string | number;
  first_name: string;
  profile_photo: string;
  email: string;
  sms_chat_sound_enable: boolean;
  sms_chat_sound_file: string;
  web_chat_sound_enable: boolean;
  web_chat_sound_file: string;
  web_chat_sound_file_url: string;
  sms_chat_sound_file_url: string;
  active_customers_ids: number[];
  active_customers: Customer[];
}

export interface ImageResponse {
  url: string;
  thumbnails: {
    large: string;
    medium: string;
    small: string;
  };
}

export interface SoundNotifications {
  id: string;
  name: string;
  file: string;
}

export interface SoundNotificationsSettingsForm {
  chat_sound_enable: boolean;
  chat_sound_file: string;
}
