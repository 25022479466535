import { wrapRoot } from 'mobx-easy';

import AuthService from 'app/services/auth';
import ConsumerService from 'app/services/consumer';
import CustomerService from 'app/services/customer';
import NotificationsService from 'app/services/notifications';
import UserService from 'app/services/user';
import HCPService from 'app/services/hcp';

import FileService from '../../services/file';
import ChatService from '../../services/chat';
import RootStore from '../RootStore';

export interface RootEnv {
  authService: AuthService;
  leadService: ConsumerService;
  chatService: ChatService;
  customerService: CustomerService;
  fileService: FileService;
  notificationsService: NotificationsService;
  userService: UserService;
  hcpService: HCPService;
  isDev: boolean;
}

export const createStore = () => {
  const env = {
    authService: new AuthService(),
    leadService: new ConsumerService(),
    chatService: new ChatService(),
    customerService: new CustomerService(),
    fileService: new FileService(),
    notificationsService: new NotificationsService(),
    userService: new UserService(),
    hcpService: new HCPService(),

    isDev: process.env.NODE_ENV === 'development',
  };

  const rootStore = wrapRoot({
    RootStore: RootStore,
    env,
  });

  return rootStore as RootStore;
};
