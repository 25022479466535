import { ConsumerStatus } from './Consumer';
import { LeadFlowType, LeadStage, PostLeadStatus } from './Lead';

export interface LeadFiltersModel {
  stage?: LeadStage;
  ordering?: string;
  concierge?: number;
  page?: number;
  status?: ConsumerStatus | '';
  post_status?: PostLeadStatus | '';
  customers?: number[];
  search?: string | '';
  flow_type?: LeadFlowType | '' | number;
  test_only?: number | string;
}

export const DefaultFilters: LeadFiltersModel = {
  stage: LeadStage.New,
  flow_type: -1,
  test_only: -1,
};

export enum ConsumerType {
  CONSUMER = 'consumer_user_type',
  HCP = 'hcp_user_type',
}

export const ConsumerTypesChipData = Object.freeze({
  testOnly: 'test',
  [ConsumerType.CONSUMER]: 'consumer',
  [ConsumerType.HCP]: 'hcp',
});

export const ConsumerTypesSwitcherData = Object.freeze({
  [ConsumerType.CONSUMER]: 'Consumer',
  [ConsumerType.HCP]: 'HCP',
});

export const HAPPITU_DATA_HINT_TEXT = Object.freeze({
  forUnCompleted: `Once you've completed the Happitu Profile, the data will be fetched
automatically and shown here. To instantly view the displayed data,
please complete the Happitu Profile and then click on the Refresh
button located above.`,
  forCompleted: 'There is no data to display.',
});
