import { ConfirmationDialog } from './Layout/ConfirmationDialog';
import { Layout } from './Layout/Layout';
import { Notifier } from './Layout/Notifier';

export default class UiStore {
  layoutStore: Layout;
  notifierStore: Notifier;
  dialogStore: ConfirmationDialog;

  constructor() {
    this.layoutStore = new Layout();
    this.notifierStore = new Notifier();
    this.dialogStore = new ConfirmationDialog();
  }
}
