import * as Sentry from '@sentry/react';
import * as React from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { createRoot } from 'react-dom/client';

import { ErrorHandlerView } from 'app/components/modules/common/ErrorBoundary';
import { StoreProvider } from 'app/stores/config/StoreContext';
import config from 'app/config';
import { Environment } from 'app/models/CommonModels';

import { createStore } from './app/stores/config/CreateStore';
import { App } from './app/App';
import * as browserHistory from './app/data/browser-history';

const rootStore = createStore();

Sentry.init({
  dsn: config.SENTRY_DSN,
  environment: config.ENVIRONMENT_NAME,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation:
        Sentry.reactRouterV5Instrumentation(browserHistory),
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate:
    config.ENVIRONMENT_NAME === Environment.Production
      ? 0.2
      : config.ENVIRONMENT_NAME === Environment.Staging
      ? 1.0
      : 0,
});

const rootElement = document.getElementById('root');

const root = createRoot(rootElement);

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: config.LAUNCHDARKLY_CLIENT_SIDE_ID,
  });

  root.render(
    <Sentry.ErrorBoundary fallback={ErrorHandlerView}>
      <LDProvider>
        <StoreProvider value={rootStore}>
          <App />
        </StoreProvider>
      </LDProvider>
    </Sentry.ErrorBoundary>
  );
})();

if (module.hot) {
  module.hot.accept();
}
