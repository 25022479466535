import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';
import {
  ConsumerModel,
  ConsumerRequestModel,
  ConsumersResult,
  ConsumerStatus,
} from 'app/models/Consumer';
import { FollowupLog } from 'app/models/FollowupLogModels';
import { HistoryResult } from 'app/models/HistoryModels';

import { RawPagingResult } from '../models/CommonModels';
import {
  Clinician,
  Customer,
  EmailModel,
  HappituDataItem,
  RawShortLeadModel,
  TelemedicineUrl,
} from '../models/Lead';
import { QuestionsCategory } from '../models/LeadQuestionsModels';

export default class ConsumerService {
  async getLeads(
    params?: string | undefined
  ): Promise<AxiosResponse<RawPagingResult<ConsumersResult>>> {
    return await Axios.get(`/surveys/?${params ? params : ''}`);
  }

  async getLead(uuid: string): Promise<AxiosResponse<ConsumerModel>> {
    return await Axios.get(`/surveys/${uuid}/`);
  }

  async getShortLeadInfo(
    sid: string
  ): Promise<AxiosResponse<RawShortLeadModel>> {
    return await Axios.get(`/surveys/conversations/${sid}/brief/`);
  }

  async updateLead(uuid: string, survey: ConsumerRequestModel) {
    return await Axios.put(`/surveys/${uuid}/`, survey);
  }

  async updateLeadStatus(uuid: string, status: ConsumerStatus) {
    return await Axios.patch(`/surveys/${uuid}/`, { status });
  }

  async getCustomersList(
    isActive: boolean = undefined
  ): Promise<AxiosResponse<RawPagingResult<Customer>>> {
    return await Axios.get('/customers/', {
      params: {
        is_active: isActive,
      },
    });
  }

  async getConditionsUrls(
    uuid: string
  ): Promise<AxiosResponse<{ name: string; url: string }[]>> {
    return await Axios.get(`/customers/${uuid}/conditions/urls/`);
  }

  async checkUserId(
    userId: string
  ): Promise<
    AxiosResponse<{ uuid: string; posthog_id: string; condition: string }>
  > {
    return await Axios.get(`/patients/token/${userId}/`);
  }

  async resendQuestionnaireLink(uuid: string): Promise<AxiosResponse> {
    return await Axios.post(`/surveys/${uuid}/followups/notify/`);
  }

  async loadFollowUpLogs(
    uuid: string
  ): Promise<AxiosResponse<RawPagingResult<FollowupLog>>> {
    return await Axios.get(`/surveys/${uuid}/followup/logs/`);
  }

  async logFollowUpRecord(
    uuid: string,
    message: string
  ): Promise<AxiosResponse<any>> {
    return await Axios.post(`/surveys/${uuid}/followup/logs/`, {
      message,
    });
  }

  async getHistory(
    luuid: string,
    // cursor?: number,
    params?: string | undefined
  ): Promise<AxiosResponse<HistoryResult>> {
    return await Axios.get(
      `/surveys/${luuid}/history/?${params ? params : ''}`
    );
  }

  async reassigneLead(uuid: string): Promise<AxiosResponse<any>> {
    return await Axios.post(`/surveys/${uuid}/assign/`);
  }

  async loadSurveyQuestions(
    survey_uuid: string
  ): Promise<AxiosResponse<QuestionsCategory[]>> {
    return await Axios.get(`/surveys/${survey_uuid}/question-categories/`);
  }

  async getTelemedicineUrl(
    uuid: string
  ): Promise<AxiosResponse<TelemedicineUrl[]>> {
    return await Axios.get(`/surveys/${uuid}/telemedicine-url/`);
  }

  async getWebTelemedicineUrl(
    uuid: string
  ): Promise<AxiosResponse<TelemedicineUrl[]>> {
    return await Axios.get(`/conversations/web/${uuid}/telemedicine-url/`);
  }

  async addDoctor(uuid: string, clinician: Clinician) {
    return await Axios.post(`/surveys/${uuid}/clinicians/`, clinician);
  }

  async updateDoctor(
    uuid: string,
    clinicianUuid: string,
    clinician: Clinician
  ) {
    return await Axios.put(
      `/surveys/${uuid}/clinicians/${clinicianUuid}/`,
      clinician
    );
  }

  async removeDoctor(uuid: string, clinicianUuid: string) {
    return await Axios.delete(`/surveys/${uuid}/clinicians/${clinicianUuid}/`);
  }

  async sendEmail(uuid: string, email: EmailModel) {
    return await Axios.post(`/surveys/${uuid}/send-email/`, email);
  }
  async changeOptIn(uuid: string, checked: boolean) {
    return await Axios.patch(`/surveys/${uuid}/opt-in/`, { opt_in: checked });
  }

  async getHappituData(
    uuid: string,
    isHCP: boolean
  ): Promise<AxiosResponse<HappituDataItem[]>> {
    return await Axios.get(
      `/surveys/${isHCP ? 'hcp-surveys/' : ''}${uuid}/happitu-data/`
    );
  }
}
