import React, { lazy } from 'react';
import { FileText, MessageCircle, Smartphone } from 'react-feather';

import { HCPIcon } from 'app/theme/Icons';

import { AccessLevel, RouteItem } from '../models/RouteModels';

const SignIn = lazy(() => import('app/components/pages/Auth'));
const Consumers = lazy(
  () => import('app/components/pages/Consusmer/Consumers')
);
const Lead = lazy(
  () => import('app/components/pages/Consusmer/ConsumerDetails')
);
const SmsChats = lazy(() => import('app/components/pages/SmsChats'));
const WebChats = lazy(() => import('app/components/pages/WebChats'));
const Profile = lazy(() => import('app/components/pages/Profile'));
const HCP = lazy(() => import('app/components/pages/HCP/HCPDetails'));
const HCPs = lazy(() => import('app/components/pages/HCP/HCPs'));

const ChangePassword = lazy(
  () => import('app/components/pages/ChangePassword')
);
const ResetPassword = lazy(
  () => import('app/components/pages/resetPassword/ResetPasswordPage')
);

export const auth: RouteItem = {
  id: 'Auth',
  path: '/',
  header: 'Auth',
  children: [
    {
      path: '/auth',
      name: 'Sign In',
      component: SignIn,
    },
    {
      path: '/auth/reset-password',
      name: 'Sign In',
      component: ResetPassword,
    },
  ],
  access: [AccessLevel.OnlyPublic],
};

const leads: RouteItem = {
  id: 'Consumers',
  path: '/leads',
  header: '',
  icon: <FileText />,
  component: Consumers,
  children: null,
  access: [AccessLevel.Auth],
};

export const lead: RouteItem = {
  id: 'Consumer',
  path: '/lead',
  header: '',
  icon: <FileText />,
  component: Lead,
  children: null,
  access: [AccessLevel.Auth],
};

export const smsChats: RouteItem = {
  id: 'SMS Texts',
  path: '/chats',
  header: '',
  icon: <Smartphone />,
  component: SmsChats,
  children: null,
  access: [AccessLevel.Auth, AccessLevel.PROFILE_TYPE_CONCIERGE],
  counter: null,
};

const webChats: RouteItem = {
  id: 'Web Chats',
  path: '/web-chats',
  header: '',
  icon: <MessageCircle />,
  component: WebChats,
  children: null,
  access: [AccessLevel.Auth],
  counter: null,
};

const profile: RouteItem = {
  id: 'Profile',
  path: '/profile',
  header: '',
  component: Profile,
  children: null,
  access: [AccessLevel.Auth],
};

const changePassword: RouteItem = {
  id: 'Change Password',
  path: '/profile/change-password',
  header: '',
  component: ChangePassword,
  children: null,
  access: [AccessLevel.Auth],
};

export const hcp: RouteItem = {
  id: 'HCP',
  path: '/hcp',
  header: '',
  icon: <FileText />,
  component: HCP,
  children: null,
  access: [AccessLevel.Auth],
};

const hcps: RouteItem = {
  id: 'HCPs',
  path: '/hcps',
  header: '',
  component: HCPs,
  children: null,
  access: [AccessLevel.Auth],
  icon: <HCPIcon />,
};

export const dashboardRoutes = [
  leads,
  lead,
  smsChats,
  webChats,
  profile,
  changePassword,
  hcp,
  hcps,
];
export const authRoutes = [auth];
export default [leads, hcps, smsChats, webChats];
