import { ConsumerStatus } from '.';
import {
  ErrorType,
  Gender,
  PagingResult,
  USTimezonesValues,
} from '../CommonModels';
import {
  BaseLeadModel,
  CaregiverRequest,
  CargiverUpdate,
  Clinician,
  InsuranceType,
  LeadErrorModel,
  LeadModel,
  LeadRequestModel,
  LeadUpdateModel,
  Patient,
} from '../Lead';

export class ConsumersResult extends PagingResult<BaseConsumerModel> {}

export class BaseConsumerModel extends BaseLeadModel {
  patient: Patient;
  status: ConsumerStatus;
  clinicians: Clinician[];
}

export interface ConsumerModel extends LeadModel, BaseConsumerModel {
  caregiver: CaregiverRequest | null;
  communication_channel: string | null;
  patient_disabled_fields: {
    gender: boolean;
    age: boolean;
    dob: boolean;
  };
}

export interface ConsumerUpdateModel extends LeadUpdateModel {
  patient: {
    first_name: string;
    last_name: string;
    phone: string;
    gender: Gender;
    dob: Date;
    age: number;
    email: string;
    insurance_member_id: string;
    insurance_group_id: string;
    insurance_type: InsuranceType;
    insurance_carrier: string;
    insurance_plan: string;
    zip_code: string;
    timezone: USTimezonesValues;
    city: string;
  };
  caregiver: CargiverUpdate | null;
  status: ConsumerStatus;
  clinicians: Clinician[];
  patient_disabled_fields: {
    gender: boolean;
    age: boolean;
    dob: boolean;
  };
}

export interface ConsumerRequestModel extends LeadRequestModel {
  patient: {
    first_name: string;
    last_name: string;
    phone: string;
    gender: Gender;
    dob: string;
    age: number;
    email: string;
    insurance_member_id: string;
    insurance_group_id: string;
    insurance_type: InsuranceType;
    insurance_carrier: string;
    insurance_plan: string;
    zip_code: string;
    timezone: string;
  };
  caregiver: CargiverUpdate | null;
  status: ConsumerStatus;
}

export interface IConsumerFormProps {
  lead: ConsumerUpdateModel;
  leadErrors: ConsumerErrorModel;
  busy: boolean;
  onChange: (lead: ConsumerUpdateModel) => void;
  onErrorChange: (lead: ConsumerErrorModel) => void;
}

export interface ConsumerErrorModel extends LeadErrorModel {
  patient: {
    first_name: ErrorType;
    last_name: ErrorType;
    phone: ErrorType;
    dob: ErrorType;
    age: ErrorType;
    zip_code: ErrorType;
    email: ErrorType;
    insurance_member_id: ErrorType;
    insurance_group_id: ErrorType;
    insurance_carrier: ErrorType;
    insurance_plan: ErrorType;
    timezone: ErrorType;
  };
  caregiver: {
    phone: ErrorType;
    email: ErrorType;
    age: ErrorType;
    zip_code: ErrorType;
  };
}
