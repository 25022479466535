import DataStore from './data/DataStore';
import UiStore from './ui/UiStore';
import DialogStore from './data/DialogStore';

export default class RootStore {
  dataStores: DataStore;
  uiStores: UiStore;
  dialogStore: DialogStore;

  init() {
    this.dataStores = new DataStore();
    this.uiStores = new UiStore();
    this.dialogStore = new DialogStore();
  }
}
