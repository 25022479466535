import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';

import { parsePagingResult } from 'app/models/CommonModels';
import { HCPFlowModel, HCPsResult } from 'app/models/HCP';
import { RootEnv } from 'app/stores/config/CreateStore';

export class HCPs {
  hcps: HCPsResult = null;
  isLoading: boolean = false;
  hcpFlows: HCPFlowModel[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  async loadHCPs(params?: string | undefined): Promise<boolean> {
    this.isLoading = true;

    try {
      const { hcpService } = getEnv<RootEnv>();
      const { data } = await hcpService.getHCPs(params);

      runInAction(() => {
        this.hcps = parsePagingResult(data);
      });

      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async loadHCPFlows(uuid: string): Promise<boolean> {
    try {
      const { hcpService } = getEnv<RootEnv>();
      const { data } = await hcpService.getHCPFlows(uuid);
      runInAction(() => {
        this.hcpFlows = data.results;
      });

      return true;
    } catch (error) {
      return false;
    }
  }
}
