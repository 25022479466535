import { makeAutoObservable } from 'mobx';

export interface ConfirmationDialogModel {
  open: boolean;
  title: string;
  message: string;
  onClose: (confirmed: boolean) => void;
  redirectTo?: string;
}

export class ConfirmationDialog {
  dialog: ConfirmationDialogModel = {
    open: false,
    title: 'Confirmation',
    message: '',
    onClose: () => {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  show(title: string, message: string, onClose, redirectTo = '') {
    this.dialog = { open: true, title, message, onClose, redirectTo };
  }

  hide() {
    this.dialog = { ...this.dialog, open: false };
  }

  reset() {
    this.dialog = {
      open: false,
      title: 'Confirmation',
      message: '',
      onClose: () => {},
    };
  }
}
