import * as React from 'react';

export enum AccessLevel {
  OnlyPublic = -3,
  Public = -2,
  Auth = -1,
  PROFILE_TYPE_CONCIERGE = 10,
  PROFILE_TYPE_COMPLIANCE = 30,
}

export type RouteItem = {
  id: string;
  path: string;
  icon?: React.Component | any;
  header: string;
  component?: React.Component | React.LazyExoticComponent<any>;
  children?: {
    path: string;
    name: string;
    component: React.Component | React.LazyExoticComponent<any>;
  }[];
  access: AccessLevel[];
  counter?: number;
};

export enum QParameters {
  SEARCH = 'search',
  PAGE = 'page',
}
