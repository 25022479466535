import {
  AppBar as MuiAppBar,
  Box,
  createStyles,
  Grid,
  Hidden,
  IconButton as MuiIconButton,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import * as React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { useStores } from 'app/stores/config/UseStores';

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.palette.common.white};
  color: ${(props) => props.theme.palette.grey['500']};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
const useStyles = makeStyles(() =>
  createStyles({
    imgPlaceholder: {
      alignItems: 'center',
      background: '#fff',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      borderRadius: '100%',
      height: 24,
      marginTop: 0,
      width: 24,
      '& img': {
        borderRadius: '100%',
        objectFit: 'cover',
      },
    },

    uploadedImg: {
      height: '100%',
      objectFit: 'contain',
      width: '100%',
    },
  })
);

const UserMenu = observer(({ onLogout, onProfile }) => {
  const {
    dataStores: { userStore, authStore },
  } = useStores();

  const classes = useStyles({});

  const [anchorMenu, setAnchorMenu] = React.useState(null);
  const open = Boolean(anchorMenu);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  return (
    <React.Fragment>
      <IconButton
        aria-owns={open ? 'menu-appbar' : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Box className={classes.imgPlaceholder}>
          {userStore.profilePhoto ? (
            <img
              src={userStore.profilePhoto}
              className={classes.uploadedImg}
              alt="image"
            />
          ) : (
            <AccountCircleOutlinedIcon style={{ fontSize: 24 }} />
          )}
        </Box>
      </IconButton>

      <Menu
        id="menu-appbar"
        keepMounted
        anchorEl={anchorMenu}
        getContentAnchorEl={null}
        open={open}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: {
            width: 160,
          },
        }}
      >
        {authStore.isConcierge && (
          <MenuItem
            onClick={() => {
              onProfile();
              closeMenu();
            }}
          >
            Profile
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            onLogout();
            closeMenu();
          }}
        >
          Log Out
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
});

const Header = ({ onDrawerToggle, onLogout, onProfile }) => {
  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}
        style={{
          boxShadow:
            '0px 2px 1px -1px #D9D9D9, 0px 1px 1px 0px #D9D9D9, 0px 1px 3px 0px #D9D9D9',
        }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Hidden mdUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item></Grid>
            <Grid item xs />
            <Grid item>
              <UserMenu onLogout={onLogout} onProfile={onProfile} />
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default observer(Header);
