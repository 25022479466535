export enum HCPStatus {
  NONE = 'none',
  ONGOING_SUPPORT = 'ongoing_support',
  UNABLE_TO_ENGAGE = 'unable_to_engage',
  UNREACHABLE = 'unreachable',
  ASSISTED_WITH_OBTAINING_SAMPLE = 'assisted_with_obtaining_sample',
  DIRECT_TO_SALES_REPRESENTATIVE_CONTACT = 'direct_to_sales_representative_contact',
  INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED = 'international_inquiry_response_provided',
  PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED = 'patient_support_program_information_provided',
  PRODUCT_INFORMATION_PROVIDED = 'product_information_provided',
  REDIRECTED_TO_MEDICAL_INFORMATION = 'redirected_to_medical_information',
  REIMBURSEMENT_INFORMATION_PROVIDED = 'reimbursement_information_provided',
  GATHERING_INGO = 'gathering_info',
}

export enum HCPViewActionTaken {
  None = 'None',
  ASSISTED_WITH_OBTAINING_SAMPLE = 'Assisted with Obtaining Sample',
  DIRECT_TO_SALES_REPRESENTATIVE_CONTACT = 'Direct to Sales Representative Contact',
  INTERNATIONAL_INQUIRY_RESPONSE_PROVIDED = 'International Inquiry Response Provided',
  PATIENT_SUPPORT_PROGRAM_INFORMATION_PROVIDED = 'Patient Support Program Information Provided',
  PRODUCT_INFORMATION_PROVIDED = 'Product Information Provided',
  REDIRECTED_TO_MEDICAL_INFORMATION = 'Redirected to Medical Information',
  REIMBURSEMENT_INFORMATION_PROVIDED = 'Reimbursement Information Provided',
}

export enum HCPQueryField {
  STAGE = 'stage',
  ORDERING = 'ordering',
  CONCIERGE = 'concierge',
  PAGE = 'page',
  STATUS = 'hcp_status',
  POST_STATUS = 'post_status',
  CUSTOMERS = 'customers',
  SEARCH = 'search',
  FLOW_TYPE = 'flow_type',
  TEST_ONLY = 'test_only',
}
