import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
} from '@material-ui/core';
import { IDialogProps } from 'app/models/CommonModels';
import React from 'react';
import { X } from 'react-feather';
import { MaterialsGroup } from 'app/models/CustomerModels';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import analytics from 'app/services/analytics';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {},
    dialogTitle: {
      padding: '50px 50px 0px 50px',
    },
    dialogContent: {
      padding: '20px 50px',
    },
    noPaddings: {
      padding: '0 !important',
    },
    [theme.breakpoints.down('sm')]: {
      dialogTitle: {
        padding: 20,
      },
      dialogContent: {
        padding: 20,
      },
      closeButton: {
        position: 'absolute',
        left: 0,
      },
    },
    mb20: {
      marginBottom: 20,
    },
  }),
);

interface IProps extends IDialogProps {
  materials: MaterialsGroup;
}

const MaterialsDialog = (props: IProps) => {
  const { onClose, materials, ...other } = props;

  const theme = useTheme();
  const isMobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles({});

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      fullScreen={isMobileDevice}
      aria-labelledby="image-title"
      {...other}
    >
      <DialogTitle className={classes.noPaddings}>
        <div className={classes.dialogTitle}>
          <Grid
            container
            alignContent="center"
            justifyContent={isMobileDevice ? 'center' : 'space-between'}
            direction={isMobileDevice ? 'row-reverse' : 'row'}
          >
            <Box>
              <Typography variant="h4" color="secondary">
                {materials?.group_name} Materials
              </Typography>
            </Box>
            <Button
              variant="text"
              onClick={() => onClose()}
              className={classes.closeButton}
            >
              <X width={35} height={35} color="#CBCBCB" />
            </Button>
          </Grid>
        </div>
      </DialogTitle>

      <DialogContent className={classes.noPaddings}>
        <div className={classes.dialogContent}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
            className={classes.mb20}
          >
            {materials?.materials.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  container
                  xs={6}
                  alignItems="center"
                  spacing={3}
                >
                  <Link
                    underline="always"
                    target="_blank"
                    href={item.link || item.file}
                    onClick={() => analytics.captureQuickLinkClicked(item)}
                  >
                    <OpenInNewIcon
                      style={{
                        fontSize: '14px',
                        marginRight: '8px',
                        verticalAlign: 'middle',
                      }}
                    />

                    {item.name}
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MaterialsDialog;
