import Auth from './Auth/Auth';
import User from './Auth/User';
import { ChatDocuments } from './Chats/ChatDocuments';
import ChatsStore from './Chats/Chats';
import { FollowupLog } from './Lead/FollowupLog';
import { LeadStore } from './Lead/LeadStore';
export default class DataStore {
  userStore: User;
  authStore: Auth;
  followupLogStore: FollowupLog;
  chatsStore: ChatsStore;
  chatDocumentsStore: ChatDocuments;
  leadStore: LeadStore;

  constructor() {
    this.authStore = new Auth();
    this.userStore = new User();
    this.followupLogStore = new FollowupLog();
    this.chatsStore = new ChatsStore();
    this.chatDocumentsStore = new ChatDocuments();
    this.leadStore = new LeadStore();
  }
}
