import { RootEnv } from 'app/stores/config/CreateStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import { ChatDocument, ChatDocumentsError } from '../../../models/ChatModels';
import RootStore from '../../RootStore';

export class ChatDocuments {
  documents: ChatDocument[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  get sentDocumentsCount(): number {
    return this.documents.filter((d) => d.selected).length || 0;
  }

  async loadDocuments(sid: string, isWebChat = false) {
    try {
      const { chatService } = getEnv<RootEnv>();

      const { data } = await chatService.loadConversationDocuments(
        sid,
        isWebChat,
      );

      runInAction(() => {
        this.documents = data;
      });
    } catch {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();
      notifierStore.notify(ChatDocumentsError.DocumentsLoadingError);
    }
  }

  async toggleDocument(sid: string, document_uuid: string, isWebChat = false) {
    try {
      const { chatService } = getEnv<RootEnv>();

      await chatService.toggleConversationDocument(
        sid,
        document_uuid,
        isWebChat,
      );

      runInAction(() => {
        const doc = this.documents.find((d) => d.uuid === document_uuid);
        doc.selected = !doc.selected;
      });
    } catch {
      const {
        uiStores: { notifierStore },
      } = getRoot<RootStore>();
      notifierStore.notify(ChatDocumentsError.ToggleDocumentError);
    }
  }
}
