import { AccessLevel } from './RouteModels';

export interface AuthModel {
  access: string;
  refresh: string;
  profile_type: AccessLevel;
}

export const DefaultAuth: AuthModel = {
  access: '',
  refresh: '',
  profile_type: AccessLevel.PROFILE_TYPE_CONCIERGE,
};
