export enum LeadStage {
  New = 'new',
  InProgress = 'in_progress',
  OnHold = 'on_hold', // Unreachable
  Done = 'done',
}

export enum LeadOrdering {
  DefaultCompleted = '-completed_dt',
}

export enum BaseLeadStatus {
  OngoingSupport = 'ongoing_support',
  PatientUnreachable = 'unreachable',
  UnableToEngage = 'unable_to_engage',
}

export enum PostLeadStatus {
  QuestionnaireSent = 'questionnaire_sent',
  QuestionnaireNotSubmitted = 'questionnaire_not_submitted',
  QuestionnaireResent = 'questionnaire_resent',
  RxPrescribed = 'rx_prescribed',
  AlternativeRxPrescribed = 'alt_rx_prescribed',
  UndefinedRxPrescribed = 'undefined_rx_prescribed',
  NoRxPrescribed = 'no_rx_prescribed',
  NoAppointment = 'no_appointment',
  NoPostConsult = 'none',
}

export enum LeadFlowType {
  Concierge = 'concierge',
  Telemedicine = 'telemedicine',
}

export enum LeadTestOnlyFilterType {
  Test = 'true',
  Leads = 'false',
}

export enum PatientQuestionType {
  Product = 10,
  Condition = 20,
  ClinicianScheduling = 30,
  Other = 40,
  Cost = 50,
  SideEffects = 60,
}

export enum SurveyQuestionType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Slider = 'slider',
  Choices = 'choices',
  ChoicesBoolean = 'boolean',
  MultipleChoices = 'multiplechoices',
  Image = 'image',
  File = 'file',
  Phone = 'phone',
}

export enum PageItemType {
  SINGLE_ANSWER = 10,
  MULTI_ANSWER = 20,
  FREE_TEXT = 30,
  TEXT_FIELD = 40,
  NUMBER = 50, //Number Field
  BOOLEAN = 60,
  PHONE_NUMBER = 70,
  DATE = 80,
  IMAGE = 90, //Photo
  SLIDER = 100,
  TERMS_OF_USE = 110,
  BUTTON = 120,
  INTERSTITIAL = 130,
  REDIRECT = 140,
  POP_UP = 150,
  EMAIL = 160,
  STATIC_IMAGE = 170,
  STATIC_LABEL = 180,
}

export enum InsuranceType {
  EPO = 10,
  HMO = 20,
  POS = 30,
  PPO = 40,
}

export enum LeadViewType {
  AddInfo = 10,
  View = 20,
}

export enum LeadCreationType {
  INCOMING_CALLER = 'incoming_caller',
  WEBCHAT = 'webchat',
  AUTOMATICALLY = 'automatically',
}
