import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv } from 'mobx-easy';

import { RootEnv } from 'app/stores/config/CreateStore';
import { IDataResult, parsePagingResult } from 'app/models/CommonModels';
import {
  BaseConciergeModel,
  ConciergeResult,
  ImageResponse,
  SoundNotificationsResult,
  UserProfile,
  DefaultUserData,
} from 'app/models/UserModels';

import { getError as getErrorMsg } from '../../../models/ErrorModels';

export default class User {
  concierges: ConciergeResult;
  userProfileData: UserProfile = DefaultUserData;
  isLoading: boolean = false;
  soundNotifications: SoundNotificationsResult = null;

  constructor() {
    makeAutoObservable(this);
  }

  async loadConcierges(params?: string | undefined): Promise<boolean> {
    try {
      const { userService } = getEnv<RootEnv>();
      const { data } = await userService.getConcierges(params);

      runInAction(() => {
        this.concierges = parsePagingResult(data);
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  async getConciergeProfileData(): Promise<boolean> {
    this.isLoading = true;
    try {
      const { userService } = getEnv<RootEnv>();
      const { data } = await userService.getConciergeProfile();

      runInAction(() => {
        this.userProfileData = data;
        localStorage.setItem('userEmail', JSON.stringify(data.email));
      });

      return true;
    } catch (error) {
      return false;
    } finally {
      this.isLoading = false;
    }
  }

  async updateConciergeProfileData(userProfileData): Promise<{
    success: boolean;
    msg: string | object;
  }> {
    this.isLoading = true;
    try {
      const { userService } = getEnv<RootEnv>();
      const { data } = await userService.updateConciergeProfile(
        userProfileData
      );

      runInAction(() => {
        this.userProfileData = data;
      });

      return {
        success: true,
        msg: 'Your profile has been successfully updated.',
      };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err || '',
      };
    } finally {
      this.isLoading = false;
    }
  }

  async uploadImage(img: FormData): Promise<IDataResult<ImageResponse>> {
    this.isLoading = true;

    try {
      const { data } = await getEnv<RootEnv>().userService.uploadSettingsImage(
        img
      );

      return {
        success: true,
        data: data,
      };
    } catch (error) {
      return { success: false, data: null };
    } finally {
      this.isLoading = false;
    }
  }

  async loadSoundNotifications(): Promise<boolean> {
    try {
      const { userService } = getEnv<RootEnv>();
      const { data } = await userService.getSoundNotifications();

      runInAction(() => {
        this.soundNotifications = parsePagingResult(data);
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  get profilePhoto() {
    return this.userProfileData?.profile_photo || '';
  }

  get webNotificationSound() {
    return this.userProfileData.web_chat_sound_file_url || '';
  }

  get smsNotificationSound() {
    return this.userProfileData.sms_chat_sound_file_url || '';
  }

  get conciergesNames(): BaseConciergeModel[] {
    return this.concierges.results;
  }

  get soundNotificationsResults() {
    return this.soundNotifications?.results;
  }

  resetLocalStorageEmail() {
    localStorage.removeItem('userEmail');
  }
  get userUUID() {
    return this.userProfileData?.uuid;
  }

  get localStorageEmail() {
    return localStorage.getItem('userEmail');
  }
}
