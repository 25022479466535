import { Theme } from '@material-ui/core';

export const RxButton = (theme: Theme) => ({
  root: {
    borderRadius: 2,
  },
  outlined: {
    border: '1px solid ' + theme.palette.secondary.dark,
  },
});
