import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';
import { FileUploadResult } from 'app/models/FileModel';

export default class FileService {
  async uploadFile(
    bodyFormData: FormData,
    onUploadProgress?: (event: ProgressEvent) => void
  ): Promise<AxiosResponse<FileUploadResult>> {
    return await Axios.post('/upload/file/', bodyFormData, {
      onUploadProgress,
    });
  }
}
