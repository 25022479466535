export const parseJwt = (token: string): any => {
  if (!token) return;

  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const parseModelErrors = (modelState: any) => {
  const errors = [];
  for (const key in modelState) {
    if (Array.isArray(modelState[key])) {
      for (let i = 0; i < modelState[key].length; i++) {
        errors.push(modelState[key][i]);
      }
    } else {
      errors.push(modelState[key]);
    }
  }

  return errors;
};

export const setToObject = (obj, prop, value) => {
  if (typeof obj === 'undefined') {
    return false;
  }

  const _index = prop.indexOf('.');
  if (_index > -1) {
    let deepObj = obj[prop.substring(0, _index)];

    if (deepObj === null) {
      deepObj = undefined;
    }

    setToObject(deepObj, prop.substr(_index + 1), value);
    return true;
  }

  obj[prop] = value;
  return true;
};
