export const RxTooltip = {
  tooltip: {
    backgroundColor: '#000',
    fontSize: 12,
    padding: 16,
    borderRadius: 2,
  },
  tooltipPlacementBottom: {
    margin: '0px !important',
    marginTop: '5px !important',
  },
};
