import { AxiosResponse } from 'axios';

import Axios from 'app/data/axios';

export default class AuthService {
  async login(email: string, password: string) {
    return await Axios.post('auth/login/', { email, password });
  }

  async loginWithSSO(tokenId: string) {
    return await Axios.post('auth/login/sso/', { token_id: tokenId });
  }

  async logout(refresh: string) {
    return await Axios.post('auth/logout/', { refresh });
  }

  async refreshToken(
    token: string
  ): Promise<AxiosResponse<{ access: string }>> {
    return await Axios.post('auth/refresh/', { refresh: token });
  }

  async resetPswd(email: string) {
    return await Axios.post('auth/password/reset/', { email });
  }

  async setNewPswd(token: string, password: string, password2: string) {
    return await Axios.post('auth/password/set/', {
      token,
      password,
      password2,
    });
  }

  async isValidResetPasswordToken(token: string) {
    return await Axios.post('auth/password/validate-token/', { token });
  }
}
