import axios from 'axios';
import { getRoot } from 'mobx-easy';

import { config } from 'app/config';

import { AuthModel } from '../models/AuthModels';
import RootStore from '../stores/RootStore';

const Axios = axios.create({
  baseURL: config.API_URL,
  timeout: 180000,
  headers: { Pragma: 'no-cache' },
});

if (config.SHOULD_IGNORE_API_PREFIX) {
  Axios.interceptors.request.use((conf) => {
    const newUrl = conf.url.replace('/api', '');
    return {
      ...conf,
      url: newUrl,
    };
  });
}

Axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    const unauthorized = error.response.status === 401;

    // reject promise in case of unsuccessful login
    if (unauthorized && originalRequest.url.indexOf('login') > -1) {
      return Promise.reject(error);
    }

    // logout user if refresh token is invalid
    if (unauthorized && originalRequest.url.indexOf('refresh') > -1) {
      const {
        dataStores: { authStore },
      } = getRoot<RootStore>();
      authStore.logout();
    }

    // reject promise in case of unsuccessful logout
    if (unauthorized && originalRequest.url.indexOf('logout') > -1) {
      return;
    }

    // get new token and recall original request
    if (unauthorized && !originalRequest._retry) {
      originalRequest._retry = true;
      let auth: AuthModel = JSON.parse(
        localStorage.getItem('auth')
      ) as AuthModel;

      if (auth && auth.refresh) {
        return Axios.post('/auth/refresh/', {
          refresh: auth.refresh,
        }).then((res) => {
          if (res.status === 200) {
            auth.access = res.data.access;
            localStorage.setItem('auth', JSON.stringify(auth));

            // update common header
            Axios.defaults.headers.common['Authorization'] =
              'JWT ' + auth.access;
            // update header in current request
            originalRequest.headers['Authorization'] = 'JWT ' + auth.access;

            return axios(originalRequest);
          }
        });
      }
    }

    // TODO: check and discuss unauthorized error resolving
    if (unauthorized) {
      const {
        dataStores: { authStore },
      } = getRoot<RootStore>();
      authStore.logout();
    }

    return Promise.reject(error);
  }
);

export default Axios;
