import { makeAutoObservable, runInAction } from 'mobx';
import { getEnv, getRoot } from 'mobx-easy';
import jwt_decode from 'jwt-decode';

import Axios from 'app/data/axios';
import { AuthModel, DefaultAuth } from 'app/models/AuthModels';
import { RootEnv } from 'app/stores/config/CreateStore';
import { AccessLevel } from 'app/models/RouteModels';
import RootStore from 'app/stores/RootStore';
import analytics from 'app/services/analytics';
import { UserType } from 'app/models/CommonModels';

import {
  getError as getErrorMsg,
} from '../../../models/ErrorModels';
import { parseJwt } from '../../../utils/Parsers';

export default class Auth {
  authData: AuthModel = DefaultAuth;

  constructor() {
    makeAutoObservable(this);
    this.sync();
  }

  async login(
    email: string,
    password: string
  ): Promise<{ success: boolean; msg: any; countdown?: number }> {
    try {
      const { authService } = getEnv<RootEnv>();
      const {
        dataStores: {
          userStore,
          leadStore: { consumersStore },
        },
      } = getRoot<RootStore>();
      let { data } = await authService.login(email, password);

      // update common header
      Axios.defaults.headers.common['Authorization'] = 'JWT ' + data.access;

      runInAction(() => (this.authData = data));
      localStorage.setItem('auth', JSON.stringify(data));

      if (email !== userStore.localStorageEmail) {
        userStore.resetLocalStorageEmail();
        consumersStore.resetLocalStorageData();
      }
      analytics.initialize();
      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: error.response.data?.countdown
          ? error.response.data?.new_error_messages
          : err,
        countdown: error.response.data?.countdown,
      };
    }
  }

  async loginWithSSO(
    tokenId: string
  ): Promise<{ success: boolean; msg: any }> {
    try {
      const { authService } = getEnv<RootEnv>();
      const {
        dataStores: {
          userStore,
          leadStore: { consumersStore },
        },
      } = getRoot<RootStore>();
      let { data } = await authService.loginWithSSO(tokenId);

      const authInfo: UserType = jwt_decode(data.access);

      // update common header
      Axios.defaults.headers.common['Authorization'] = 'JWT ' + data.access;

      runInAction(() => (this.authData = data));
      localStorage.setItem('auth', JSON.stringify(data));

      if (authInfo.email !== userStore.localStorageEmail) {
        userStore.resetLocalStorageEmail();
        consumersStore.resetLocalStorageData();
      }
      analytics.initialize();
      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err,
      };
    }
  }
  async logout() {
    try {
      const { authService } = getEnv<RootEnv>();
      const {
        dataStores: {
          userStore,
          leadStore: { consumersStore },
        },
      } = getRoot<RootStore>();

      await authService.logout(this.authData.refresh);
      userStore.resetLocalStorageEmail();
      consumersStore.resetLocalStorageData();

      runInAction(() => {
        this.reset();
        location.replace('auth');
        analytics.reset();
      });
    } catch (error) {
      /* ignore it */
    }
  }

  async setNewPswd(
    token: string,
    password: string,
    password2: string
  ): Promise<{ success: boolean; msg: string }> {
    try {
      const { authService } = getEnv<RootEnv>();
      await authService.setNewPswd(token, password, password2);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err.toString() || '',
      };
    }
  }

  async resetPswd(email: string): Promise<{ success: boolean; msg: string }> {
    try {
      const { authService } = getEnv<RootEnv>();

      await authService.resetPswd(email);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err.toString() || '',
      };
    }
  }

  async refresh() {
    try {
      if (!this.refreshToken) {
        return;
      }
      const { authService } = getEnv<RootEnv>();

      const { data } = await authService.refreshToken(this.refreshToken);
      analytics.initialize();

      // update common header
      Axios.defaults.headers.common['Authorization'] = 'JWT ' + data.access;

      runInAction(() => {
        this.authData.access = data.access;
        localStorage.setItem('auth', JSON.stringify(this.authData));
      });

      return data.access;
    } catch (error) {
      /* ignore it */
    }
  }

  async isValidResetPasswordToken(
    token: string
  ): Promise<{ success: boolean; msg: string }> {
    try {
      const { authService } = getEnv<RootEnv>();

      await authService.isValidResetPasswordToken(token);

      return { success: true, msg: '' };
    } catch (error) {
      const err = getErrorMsg(error);
      return {
        success: false,
        msg: err.toString() || '',
      };
    }
  }

  sync() {
    let auth: AuthModel = JSON.parse(localStorage.getItem('auth')) as AuthModel;

    if (auth) {
      this.authData = auth;
      Axios.defaults.headers.common['Authorization'] = 'JWT ' + auth.access;
    }
  }

  reset() {
    localStorage.removeItem('auth');
    analytics.reset();
    this.authData = DefaultAuth;
    delete Axios.defaults.headers.common['Authorization'];
  }

  get isAuth(): boolean {
    const jwtObj = parseJwt(this.authData?.access);

    return (
      !!this.authData && !!jwtObj && new Date() < new Date(+`${jwtObj.exp}000`)
    );
  }

  get userId(): number {
    return +(parseJwt(this.authData.access)?.user_id || -1);
  }

  get refreshToken(): string {
    let res = '';

    if (this.authData.refresh) {
      res = this.authData.refresh;
    } else {
      let auth: AuthModel = JSON.parse(
        localStorage.getItem('auth')
      ) as AuthModel;

      res = auth.refresh;
    }

    return res;
  }

  get showByRole(): boolean {
    switch (this.authData?.profile_type) {
      case AccessLevel.PROFILE_TYPE_COMPLIANCE:
        return false;
      default:
        return true;
    }
  }

  get isConcierge() {
    return this.authData?.profile_type === AccessLevel.PROFILE_TYPE_CONCIERGE;
  }

  get expTimeInMs() {
    const jwtObj = parseJwt(this.authData?.access);
    const t1 = new Date(+`${jwtObj.exp}000`);

    const t2 = new Date();

    const dif = t1.getTime() - t2.getTime();

    return dif;
  }

  get profileType() {
    return this.authData?.profile_type;
  }
}
