import { format } from 'date-fns';

import { toPhoneNumberWithoutCharacters } from 'app/utils/PhoneNumberHelpers';

import {
  ConsumerModel,
  ConsumerRequestModel,
  ConsumerStatus,
  ConsumerUpdateModel,
} from '.';
import { DateFormat } from '../CommonModels';
import { getISODate, parseTimezone } from '../Lead';

export function getConsumerStatusStr(
  status: ConsumerStatus | null | undefined
): string {
  switch (status) {
    case ConsumerStatus.None:
      return 'None';
    case ConsumerStatus.GatheringInfo:
      return 'Gathering Consumer Info';
    case ConsumerStatus.OngoingSupport:
      return 'Ongoing Support';
    case ConsumerStatus.PatientUnreachable:
      return 'Consumer Unreachable';
    case ConsumerStatus.UnableToEngage:
      return 'Unable to Engage';
    case ConsumerStatus.Done:
      return 'Done';
    default:
      return 'None';
  }
}

export function parseConsumer(lead: ConsumerModel): ConsumerUpdateModel {
  let res = {
    shortUuid: lead.uuid?.substring(0, 4) || '-',
    ...lead,
    assigned_dt: getISODate(lead.assigned_dt),
    caregiver: lead.caregiver
      ? {
          ...lead.caregiver,
          zip_code: lead.caregiver?.zip_code?.code || '',
          phone: lead.caregiver?.phone ?? '',
        }
      : null,
    patient: {
      ...lead.patient,
      dob: lead.patient.dob ? getISODate(lead.patient.dob) : null,
      age: lead.patient.age ? lead.patient.age : null,
      zip_code: lead.patient?.zip_code?.code || '',
      timezone: parseTimezone(lead.patient.timezone),
      phone: lead.patient?.phone ?? '',
    },
    clinicians: lead.clinicians?.map((clinician) => {
      return {
        full_name: '',
        address: '',
        ...clinician,
        phone: clinician?.phone ?? '',
      };
    }),
    status: lead.status ? lead.status : ConsumerStatus.GatheringInfo,
  } as ConsumerUpdateModel;

  return res;
}

export function parseSurveyUpdate(
  sur: ConsumerUpdateModel
): ConsumerRequestModel {
  return {
    ...sur,
    status: sur.status,
    patient: {
      ...sur.patient,
      dob: sur.patient.dob ? format(sur.patient.dob, DateFormat.Generic) : null,
      timezone: sur.patient.timezone || undefined,
      phone: sur.patient?.phone
        ? toPhoneNumberWithoutCharacters(sur.patient?.phone)
        : '',
    },
    caregiver: sur.caregiver
      ? {
          ...sur.caregiver,
          phone: sur.caregiver?.phone
            ? toPhoneNumberWithoutCharacters(sur.caregiver?.phone)
            : '',
        }
      : undefined,
    clinicians: sur.clinicians.map((clinician) => ({
      ...clinician,
      phone: clinician?.phone
        ? toPhoneNumberWithoutCharacters(clinician.phone)
        : '',
    })),
    patient_disabled_fields: undefined,
  } as ConsumerRequestModel;
}
